import React, { useEffect } from 'react';
import styled from 'styled-components';
import anime from 'animejs/lib/anime.es.js';

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100vw;
  margin-top: 10rem;

  div:first-child {
    max-width: 100rem;
    width: 85%;
    border-top: 1px solid var(--green-light);
    padding-top: 3rem;
    display: flex;
    justify-content: space-between;
  }

  ul {
    will-change: transform;
    display: flex;

    li {
      margin-left: 2rem;

      &:first-child {
        display: none;
        font-size: 1.2rem;
        color: var(--orange);
        text-transform: uppercase;

        @media (min-width: 500px) {
          display: block;
        }
      }
    }
  }

  p {
    max-width: 60rem;
    width: 85%;
    font-size: clamp(1.6rem, 3vw, 2.5rem);
    line-height: 160%;
    letter-spacing: 0.03em;

    @media (min-width: 800px) {
      width: 60%;
    }
  }
`;

const Title = styled.span`
  font-size: clamp(1.5rem, 3vw, 3rem);
  text-transform: uppercase;
  font-weight: 600;
`;

const Loop = styled.div`
  margin: 0 auto;
  white-space: nowrap;
  margin: clamp(5rem, 6vw, 6rem) 0;

  span {
    font-family: 'Source Serif Pro';
    font-size: 6vw;
    color: var(--orange);
    font-weight: 500;
    letter-spacing: 0.5vw;
    line-height: 100%;

    @media (min-width: 800px) {
      -webkit-text-fill-color: white;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: var(--orange);
    }
  }
`;

const Info = ({ title, children }) => {
  useEffect(() => {
    anime({
      targets: '.loop-infinity',
      translateX: '-87vw',
      loop: true,
      duration: 10000,
      easing: 'linear',
    });
  }, []);

  return (
    <Section>
      <div>
        <Title>{title}</Title>
        <ul>
          <li>Social</li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/Mabastudio-architecture-101603771492116"
            >
              Facebook
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/mabastudio"
            >
              Instagram
            </a>
          </li>
        </ul>
      </div>
      <Loop className="loop-infinity">
        <span>
          mabastudio / mabastudio / mabastudio / mabastudio / mabastudio /
          mabastudio / mabastudio
        </span>
      </Loop>
      {children}
    </Section>
  );
};

export default Info;
